import { isBrowser, isMobile, isTablet } from "react-device-detect";

const RedirectionComponent = () => {
  // Check if the URL path contains "share"
  const isSharePath = window.location.pathname.includes("share");
  const deviceType = isBrowser
    ? isTablet
      ? "Tablet"
      : "Desktop"
    : isMobile
    ? "Mobile"
    : "Unknown";
  // If the path contains "share", redirect to the Play Store
  if (isSharePath) {
    if (deviceType === "Desktop" || deviceType === "Unknown") {
      window.location.href = "https://www.ingenkart.com";
    } else {
      window.location.href =
        "https://play.google.com/store/apps/details?id=com.ingenkart";
    }
  }

  // Render nothing or a loading spinner while the redirection happens
  return null;
};

export default RedirectionComponent;
