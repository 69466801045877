import { createSlice } from "@reduxjs/toolkit";

const youTubeTokenSlice = createSlice({
  name: "accessToken",
  initialState: {},
  reducers: {
    setYouTubeAccessToken: (state, { payload }) => {
      state = payload.data;
    },
  },
});

export const { setYouTubeAccessToken } = youTubeTokenSlice.actions;

export default youTubeTokenSlice.reducer;
