import React, { useEffect } from "react";
import Logo from "../../assets/ingenkart_large_logo.png";
import { Button } from "antd";
import useViewport from "../../viewport/useViewport";
import { useLocation, useParams, useSearchParams } from "react-router-dom";

// A custom hook that builds on useLocation to parse
// the query string for you.
function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}
const YouTubeAuthorize = () => {
  const { isTablet } = useViewport();
  const { userId } = useSearchParams();
  const query = useQuery();
  useEffect(() => {
    localStorage.setItem(
      "USER_ID_YOU_TUBE",
      JSON.stringify(query.get("userId"))
    );
  }, []);

  return (
    <div
      className="flexCenter"
      style={{
        minHeight: "95vh",
        width: "100%",
        flexDirection: "column",
      }}
    >
      <img
        src={Logo}
        loading="eager"
        alt="ingenkart logo"
        style={{
          height: "auto",
          width: "18rem",
          marginLeft: isTablet ? -50 : 0,
        }}
      />
      <div
        style={{ padding: "1rem 1.3rem", maxWidth: isTablet ? "100%" : "30%" }}
      >
        <p
          style={{
            textAlign: "left",
          }}
        >
          Hi, Ingenkart is requiring to do the following:
        </p>
        <h5
          className="mt2"
          style={{
            fontWeight: 700,
            textAlign: "left",
            fontSize: 18,
          }}
        >
          Access your profile info
        </h5>
        <p
          style={{
            textAlign: "left",
            fontWeight: 300,
          }}
        >
          {" "}
          username, account type
        </p>
        <h5
          className="mt2"
          style={{
            fontWeight: 700,
            textAlign: "left",
            fontSize: 18,
          }}
        >
          Access your media
        </h5>
        <p
          style={{
            textAlign: "left",
            fontWeight: 300,
          }}
        >
          {" "}
          caption, media count,media type, media url, permalink, timestamp,
          thumbnail
        </p>
        <div
          className="flexBetween mt2"
          style={{
            width: "100%",
          }}
        >
          <Button className="button">Cancel</Button>

          <a href="https://accounts.google.com/o/oauth2/v2/auth/oauthchooseaccount?access_type=offline&scope=https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fyoutube.readonly&response_type=code&client_id=374514607044-p1mj3sdjm6aup74hdiubvckhdggc3b8l.apps.googleusercontent.com&redirect_uri=https%3A%2F%2Fingenkart.com%2Fyoutube&service=lso&o2v=2&flowName=GeneralOAuthFlow">
            <Button type="primary" className="button" style={{ width: "100%" }}>
              Authorize YouTube
            </Button>
          </a>
        </div>
      </div>
    </div>
  );
};

export default YouTubeAuthorize;
