import { ImageList } from "./ImageList";

export const ServiceCardData = [
  {
    title1: "Campaign Strategy",
    title2: "Development",
    description:
      "With our campaign strategy development services, we help our clients connect with their target audience and achieve their business goals through effective influencer partnerships.",
    pointers: [
      "Determine your campaign goals and objectives.",
      "Establish a budget and compensation structure for your influencers.",
      "Develop a clear and concise brief for your influencers, content requirements, and any guidelines or restrictions.",
    ],
    image: ImageList.Campaign_Strategy_Image,
  },
  {
    title1: "Influencer Research",
    title2: "and Selection",
    description:
      "Looking for Perfect Influencer for Your Next Campaign? Use Ingenkart to simplify the research and selection process. We allow you to search influencers and provide tools for managing your campaign.",
    pointers: [
      "Look for influencers with a genuine connection to your brand.",
      "Analyse an influencer's past collaborations to determine if they align with your brand.",
      "Evaluate the quality of an influencer's content. Check their engagement rate, demographic, and authenticity.",
    ],
    image: ImageList.Influencer_Research_Image,
  },
  {
    title1: "Content Creation &",
    title2: "Distribution",
    description:
      "You can work with influencers on our platform to create and distribute high-quality content that resonates with their followers, ultimately driving engagement and conversions for your marketing campaign.",
    pointers: [
      "Work collaboratively with your influencers.",
      "Use our platform to plan and distribute content strategically.",
      "Utilise the unique strengths of each influencer to create varied and interesting content.",
    ],
    image: ImageList.Content_Creation_Image,
  },
  {
    title1: "Performance Tracking",
    title2: "and Reporting",
    description:
      "By effectively tracking and reporting on the performance of your influencer marketing campaign, you can identify what's working and what's not, optimise your approach, and achieve better results overall.",
    pointers: [
      "Use our tracking tools and software to monitor the performance of your campaign.",
      "Continuously iterate and improve your campaign, using performance data.",
      "We use different reporting formats, such as dashboards, graphs, and charts, to communicate the results.",
    ],
    image: ImageList.Performance_Tracking_Image,
  },
];

export const FeaturesData = [
  {
    imageUrl: ImageList.Hash_Image,
    title: "Robust influencer search and selection tools",
    subTitle:
      "These are essential for identifying the right influencers for a marketing campaign.Engage effectively.",
  },
  {
    imageUrl: ImageList.Pie_Image,
    title: "Intuitive campaign management dashboard",
    subTitle:
      "Our intuitive campaign management dashboard offers a centralised platform to organise all campaign details.",
  },
  {
    imageUrl: ImageList.Stopwatch_Image,
    title: "Real-time performance tracking and reporting",
    subTitle:
      "Our real-time performance tracking dashboard provides analytics and reporting for your campaigns.",
  },
  {
    imageUrl: ImageList.Message_Image,
    title: "Direct messaging and collaboration with influencers",
    subTitle:
      "With easy and secure access to influencers, you can efficiently manage campaigns and share content.",
  },
  {
    imageUrl: ImageList.Dollar_Image,
    title: "Easy and Efficient way to trasnfer money",
    subTitle:
      "Our platform offers an easy and efficient way to transfer money to influencers in just a few clicks.",
  },
];
