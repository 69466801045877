import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import { MobileWrapper } from "./Style";
import { ImageList } from "../../../config/ImageList";
import useViewport from "../../../viewport/useViewport";

const MobileCard = () => {
  const { height } = useViewport();
  const [distance, setDistance] = useState(0);

  useEffect(() => {
    function handleScroll() {
      const distanceFromTop = window.scrollY;
      setDistance(distanceFromTop);
    }
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const Variants = {
    offscreen: {
      y: 100,
    },
    offscreenSide: {
      y: 200,
    },
    onscreen: {
      y: 25,
      transition: {
        type: "spring",
        bounce: 0.1,
        duration: 0.5,
      },
    },
    onscreenMobile: {
      y: 25,

      transition: {
        type: "spring",
        bounce: 0.1,
        duration: 1.5,
      },
    },
  };
  let mobilePosition =
    distance < 200 ? -78 : distance > 420 ? -220 : `-${distance - 178}px`;
  return (
    <MobileWrapper
      className="flexCenter mt2"
      data-aos="fade-up"
      height={height}
    >
      {/* {JSON.stringify(distance, null, 2)} */}
      <motion.div
        className="phoneImage"
        style={{
          position: "relative",
          right: mobilePosition,
          transitionDuration: "0.2s",
        }}
        // whileHover={{
        //   translateY: -6,
        //   scale: 1.05,
        //   right: -70,
        //   transitionDuration: "0.2s",
        // }}
        variants={Variants}
        initial="offscreenSide"
        whileInView="onscreenMobile"
        viewport={{ once: true, amount: 0.4 }}
      >
        <img
          src={ImageList.Mobile_Left}
          alt="mobile layout"
          className="mobile_side"
        />
      </motion.div>
      <motion.div
        style={{
          zIndex: 2,
          top: -20,
          position: "relative",
          transitionDuration: "0.2s",
        }}
        whileHover={{ scale: 1.05, top: -12, transitionDuration: "0.4s" }}
        variants={Variants}
        initial="offscreen"
        whileInView="onscreen"
        viewport={{ once: true, amount: 0.8 }}
      >
        <img
          src={ImageList.Mobile_Center}
          alt="mobile layout"
          className="mobile_center"
        />
      </motion.div>{" "}
      <motion.div
        style={{
          position: "relative",
          left: mobilePosition,
          zIndex: 1,
          transitionDuration: "0.2s",
        }}
        // whileHover={{
        //   translateY: -6,
        //   scale: 1.05,
        //   left: -70,
        //   transitionDuration: "0.2s",
        // }}
        variants={Variants}
        initial="offscreenSide"
        whileInView="onscreenMobile"
        viewport={{ once: true, amount: 0.4 }}
      >
        <img
          src={ImageList.Mobile_Right}
          alt="mobile layout"
          className="mobile_side"
        />
      </motion.div>
    </MobileWrapper>
  );
};

export default MobileCard;
