import { Result, Button } from "antd";
import React from "react";
import { getAccessToken, getInstaPost } from "../../functions/instagram";
import { useSelector } from "react-redux";
import { useState } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { useEffect } from "react";
// A custom hook that builds on useLocation to parse
// the query string for you.
function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}
const InstagramThank = () => {
  // hooks
  const user = useSelector((state) => state.user);
  const query = useQuery();

  const navigate = useNavigate();
  let [searchParams] = useSearchParams();

  // state
  const [instaData, setInstaData] = useState(null);

  // lifecycle methods

  useEffect(() => {
    const myUserId =
      localStorage.getItem("USER_ID_INSTA") !== ""
        ? JSON.parse(localStorage.getItem("USER_ID_INSTA"))
        : "";
    retrieveAccessToken(myUserId);
  }, []);

  const retrieveAccessToken = async (userId) => {
    try {
      await getAccessToken(userId, query.get("code"));
      const resp = await getInstaPost(userId, user?.token);
      setInstaData(resp.data.posts);
    } catch (e) {
      console.log(e.response.data);
    }
  };
  return (
    <div
      className="flexCenter"
      style={{
        height: "100vh",
      }}
    >
      <Result
        status="success"
        title="Successfully linked the Instagram profile!"
        subTitle="You can move back to the app and enjoy"
        extra={[
          <Button type="primary" key="console" disabled>
            Go Back To Back
          </Button>,
        ]}
      />
      {/* <p>{JSON.stringify(instaData, null, 4)}</p> */}
    </div>
  );
};

export default InstagramThank;
