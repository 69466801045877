import React from "react";
import ComingSoon from "../../components/comingSoon/ComingSoon";

const AboutScreen = () => {
  return (
    <div>
      <ComingSoon />
    </div>
  );
};

export default AboutScreen;
