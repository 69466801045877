import React from "react";
import { Col, Row } from "antd";
import { HomeWrapper } from "./HomeStyle";
import MobileCard from "../../components/card/MobileCard";
import { Button, Typography } from "@mui/material";
import { ImageList } from "../../config/ImageList";
import StarryVectors from "../../components/comp/home/starryVectors";
import useViewport from "../../viewport/useViewport";
import PartOfSuccess from "../../components/comp/home/partOfSuccess";
import ServiceOverview from "../../components/comp/home/serviceOverview";
import { Colors } from "../../config/Colors";
import StatsCount from "../../components/comp/home/statsCount";
import Features from "../../components/comp/home/features";
import ContactUsForm from "../../components/form";
import GoogleLocation from "../../components/comp/home/location";
import ScreenShots from "../../components/comp/home/screenShot";
import { logoutUser } from "../../redux/currentUserSlice";
import { signOut } from "firebase/auth";
import { auth } from "../../firebaseConfig";
import { useDispatch } from "react-redux";

const HomeScreen = () => {
  const { isMobile } = useViewport();
  const dispatch = useDispatch();

  const logout = async () => {
    await signOut(auth)
      .then(() => {
        console.log("User signed out.");
        dispatch(logoutUser());
      })
      .catch((error) => {
        console.error(error);
      });
  };
  return (
    <>
      <HomeWrapper className="flexCenter" isMobile={isMobile}>
        <StarryVectors />
        {/* heading */}
        <Typography
          variant={isMobile ? "h4" : "h3"}
          className="landing_heading fontLato mt1"
        >
          Generate engagement
        </Typography>

        <Typography variant="h2" className="landing_heading2 fontLato">
          by India’s top Influencers<span className="exclamation_mark">!</span>
        </Typography>
        {/* download button */}
        <a
          href="https://play.google.com/store/apps/details?id=com.ingenkart"
          target="_blank"
          rel="noopener noreferrer"
          className="download_button flexAround mt2"
        >
          <Typography className="download_text">Download</Typography>
          <img
            src={ImageList.PlayStore_Icon}
            alt="icon"
            className="store_icon"
            style={{
              borderRight: "1px solid grey",
              paddingRight: "8px",
              width: "auto",
            }}
          />
          <img
            src={ImageList.AppStore_Icon}
            alt="icon"
            className="store_icon"
          />
        </a>
        {/* 3 mobiles */}
        {isMobile ? (
          <img
            src={ImageList.Mobile_Landing}
            alt="banner"
            className="mobile_banner"
          />
        ) : (
          <MobileCard />
        )}
        {/* <Button onClick={logout}>Logout</Button> */}
        {/* ============================================================== */}
        {/* =================SECOND DIV=================================== */}
        {/* ============================================================== */}
        <PartOfSuccess />
        <div
          className="flexCenter"
          style={{
            width: "100%",
            background: Colors.greyF5,
            overflow: "hidden",
            flexDirection: "column",
            padding: isMobile ? "0.7rem" : "2rem",
          }}
        >
          <ServiceOverview />
          <Features />
          <StatsCount />
          {/* contact form and location */}
          <Row
            gutter={[20, 24]}
            style={{ width: "100%", maxWidth: "100%" }}
            className="mt2"
            name="contact"
          >
            <Col
              xs={24}
              sm={24}
              md={24}
              lg={16}
              style={{
                // paddingLeft: 0,
                display: "flex",
              }}
            >
              <ContactUsForm />
            </Col>
            <Col
              xs={24}
              sm={24}
              md={24}
              lg={8}
              style={
                {
                  // paddingRight: 0,
                  // display: "flex",
                }
              }
            >
              <GoogleLocation />
            </Col>
          </Row>
          {/* last div */}
          <ScreenShots />
        </div>
      </HomeWrapper>
    </>
  );
};

export default HomeScreen;
