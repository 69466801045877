import styled from "styled-components";
import { Colors } from "../../../config/Colors";
import { ImageList } from "../../../config/ImageList";
import { BorderRadius, FontType } from "../../../config/Values";

export const LoginWrapper = styled.div`
  width: 100%;
  height: 100vh;
  background-image: url(${ImageList.LoginImageBg});
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: cover;
  object-fit: cover;
  .nextButton {
    background-color: ${Colors.lightPurple} !important;
    color: ${Colors.white} !important;
    text-transform: capitalize;
    font-weight: 700;
    font-family: ${FontType.openSans}, sans-serif !important;
    border: 2px solid ${Colors.lightPurple};
    padding-top: 0.6rem;
    padding-bottom: 0.6rem;
    border-radius: ${BorderRadius.smallRadius};
  }
`;
