import styled from "styled-components";

export const PrivacyPolicyWrapper = styled.div`
  color: #000;
  font-size: 1.14em;
  font-weight: 300;
  font-family: sans-serif;
  padding: ${(prop) => (prop.isMobile ? "5rem 2rem" : "5rem 3rem")};
  .heads {
    font-size: 1.75em;
    font-weight: bold;
    margin: 10px 0px;
  }

  .subHead {
    font-size: 1.32em;
    font-weight: bold;
  }

  .smallHead {
    font-weight: bold;
  }
  .blankHeadDiv {
    height: 1.4em;
  }
  .blankDiv {
    height: 1.2em;
  }

  .listData {
    margin-top: 10px;
  }

  .listItems {
    margin-bottom: 0.8em;
    marg
  }

  .blank {
    margin-bottom: 1em;
  }
  .text {
  line-height: ${(prop) => (prop.isMobile ? "2.1rem" : "3rem")};
  }
`;
