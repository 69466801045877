import styled from "styled-components";
import { Colors } from "../../../../config/Colors";
import { Gradient } from "../../../../config/Values";

export const ServiceWrapper = styled.div`
  min-height: 80vh;
  width: 100%;
  max-width: 100% !important;
  background-color: ${Colors.greyF5};
  /* background: red; */
  /* margin-top: ${(prop) => prop.isMobile && "-2rem"}; */
  .service_heading {
    background: ${Gradient.primaryRadial};
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    font-style: normal;
    font-weight: 700;
    font-size: 2.25rem;
  }
  .service_sub_heading {
    font-weight: 400;
    font-size: 1rem;
    margin-top: ${(prop) => prop.isMobile && "1rem"};
  }
  .info_card_cotainer {
    flex-wrap: wrap;
    /* padding: ${(prop) => (prop.isMobile ? "0 1rem" : "0 2rem")}; */
  }
`;
