import HandshakeImageIcon from "../assets/Handshake.png";
import MoneyBagImageIcon from "../assets/MoneyBag.png";
import TagsImageIcon from "../assets/Tags.png";
import InstagramImageIcon from "../assets/Instagram.png";
import YouTubeImageIcon from "../assets/YouTube.png";
import FacebookImageIcon from "../assets/Facebook.png";
import GoldenTickImageIcon from "../assets/GoldenTick.png";
import CalenderImageIcon from "../assets/Calender.png";
import RunningCampaignImageIcon from "../assets/RunningCampaign.png";
import UserImageIcon from "../assets/User.png";
import AcceptImageIcon from "../assets/Accept.png";
import RejectImageIcon from "../assets/Reject.png";
import AcceptFilledImageIcon from "../assets/AcceptFilled.png";
import EyeImageIcon from "../assets/Eye.png";
import ReshootImageIcon from "../assets/Reset.png";
import InstaReelImageIcon from "../assets/InstaReel.png";
import HumanHeadImageIcon from "../assets/HumanHead.png";
import LoudspeakerImageIcon from "../assets/Loudspeaker.png";
import YoutubeShortsImageIcon from "../assets/YoutubeShorts.png";
import MoneyBagGreenImageIcon from "../assets/MoneyBagGreen.png";
import TagBlackImageIcon from "../assets/TagBlack.png";
import UploadFileImageIcon from "../assets/FileUpload.png";
import UploadVideoImageIcon from "../assets/VideoUpload.png";
import LoginImageBg from "../assets/loginBg.png";
import Logo from "../assets/logo.svg";
import ComingSoon from "../assets/comingsoon.png";
import Mobile_Center from "../assets/mobile_center.png";
import Mobile_Right from "../assets/mobile_right.png";
import Mobile_Left from "../assets/mobile_left.png";
import Circle_Bg from "../assets/CircleBg.svg";
import Circle_Bg_Png from "../assets/circle.png";
import PlayStore_Icon from "../assets/playstore.png";
import AppStore_Icon from "../assets/appstore.png";
import Ellipse_Bg from "../assets/Ellipse13.png";
import Starry_Vector_1 from "../assets/vector/Star1.png";
import Starry_Vector_2 from "../assets/vector/Star2.png";
import Starry_Vector_3 from "../assets/vector/Star3.png";
import Starry_Vector_4 from "../assets/vector/Star4.png";
import Starry_Vector_5 from "../assets/vector/Star5.png";
import Starry_Vector_6 from "../assets/vector/Star6.png";
import Starry_Vector_7 from "../assets/vector/Star7.png";
import Starry_Vector_8 from "../assets/vector/Star8.png";
import Mobile_Landing from "../assets/BGMobile.png";
import Hamburger_Icon from "../assets/ham.png";
import Footer_Logo from "../assets/logo_footer.png";
import FB_Icon from "../assets/fb_social_icon.png";
import Twitter_Icon from "../assets/twitter_social_icon.png";
import Instagram_Icon from "../assets/instagram_social_icon.png";
import Ingenkart_Text_Logo from "../assets/ingenkart_text_logo.png";
import Starry_Vector_9 from "../assets/vector/star9.png";
import Checkmark_Image from "../assets/checkmark.png";
import Hash_Image from "../assets/hash_image.png";
import Dollar_Image from "../assets/dollar_image.png";
import Stopwatch_Image from "../assets/stopwatch_image.png";
import Pie_Image from "../assets/pie_image.png";
import Message_Image from "../assets/message_image.png";
import Selfie_Gif from "../assets/gif/selfie_anim.gif";
import Speaker_Gif from "../assets/gif/speaker_anim.gif";
import Shoping_Bag_Gif from "../assets/gif/shopping_bag_anim.gif";
import Message_Curve_Arrow from "../assets/reach_us_arrow.png";
import Location_Image from "../assets/location_map.jpg";
import GMap_Options_Image from "../assets/gMap_options.png";
import App_Screenshot_Image from "../assets/app_screenshot.png";
import Colored_checkmark_Image from "../assets/colored_chackmark.png";
import Play_Store_Badge from "../assets/google-play-badge.png";
import App_Store_Badge from "../assets/app-store-badge.png";
import Campaign_Strategy_Image from "../assets/Service_card1.png";
import Influencer_Research_Image from "../assets/Service_card2.png";
import Content_Creation_Image from "../assets/Service_card3.png";
import Performance_Tracking_Image from "../assets/Service_card4.png";

export const ImageList = {
  IngenKartLogo: Logo,
  InstagramImageUrl:
    "https://res.cloudinary.com/axborapp/image/upload/v1673517654/Instagram_oywebj.png",
  FacebookImageUrl:
    "https://res.cloudinary.com/axborapp/image/upload/v1673517654/Facebook_vf0rv9.png",
  YouTubeImageUrl:
    "https://res.cloudinary.com/axborapp/image/upload/v1673517654/YouTube_a4nvob.png",
  InstagramReelImageUrl:
    "https://res.cloudinary.com/axborapp/image/upload/v1673535103/image_24_irv52i.png",
  YouTubeShortsImageUrl:
    "https://res.cloudinary.com/axborapp/image/upload/v1673535103/image_23_ve9kmd.png",
  HandshakeImageIcon,
  MoneyBagImageIcon,
  TagsImageIcon,
  InstagramImageIcon,
  YouTubeImageIcon,
  FacebookImageIcon,
  GoldenTickImageIcon,
  CalenderImageIcon,
  RunningCampaignImageIcon,
  UserImageIcon,
  HumanHeadImageIcon,
  AcceptImageIcon,
  RejectImageIcon,
  ReshootImageIcon,
  AcceptFilledImageIcon,
  EyeImageIcon,
  InstaReelImageIcon,
  YoutubeShortsImageIcon,
  MoneyBagGreenImageIcon,
  LoudspeakerImageIcon,
  TagBlackImageIcon,
  UploadFileImageIcon,
  UploadVideoImageIcon,
  LoginImageBg,
  ComingSoon,
  Mobile_Center,
  Mobile_Left,
  Mobile_Right,
  Circle_Bg,
  Circle_Bg_Png,
  PlayStore_Icon,
  AppStore_Icon,
  Ellipse_Bg,
  Starry_Vector_1,
  Starry_Vector_2,
  Starry_Vector_3,
  Starry_Vector_4,
  Starry_Vector_5,
  Starry_Vector_6,
  Starry_Vector_7,
  Starry_Vector_8,
  Starry_Vector_9,
  Mobile_Landing,
  Hamburger_Icon,
  Footer_Logo,
  FB_Icon,
  Twitter_Icon,
  Instagram_Icon,
  Ingenkart_Text_Logo,
  Checkmark_Image,
  Hash_Image,
  Dollar_Image,
  Stopwatch_Image,
  Pie_Image,
  Message_Image,
  Selfie_Gif,
  Speaker_Gif,
  Shoping_Bag_Gif,
  Message_Curve_Arrow,
  Location_Image,
  GMap_Options_Image,
  App_Screenshot_Image,
  Colored_checkmark_Image,
  Play_Store_Badge,
  App_Store_Badge,
  Campaign_Strategy_Image,
  Influencer_Research_Image,
  Content_Creation_Image,
  Performance_Tracking_Image,
};
