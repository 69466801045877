import styled from "styled-components";
import { Colors } from "../../../../config/Colors";
import { BorderRadius } from "../../../../config/Values";

export const StatsWrapper = styled.div`
  min-height: 30vh;
  width: 100%;
  overflow: hidden;
  /* background-color: red; */
  .stat_card_container {
    position: relative;
    border-radius: ${BorderRadius.largeRadius};
    height: 15.925rem;
    width: ${(prop) => (prop.isTablet ? "100%" : "100%")} !important;
    padding: 2rem;
  }
  #selfie {
    background: conic-gradient(
      from 154.38deg at 0% -28.9%,
      #b2b0f7 0deg,
      #9797f7 360deg
    );
    z-index: 1 !important;
  }
  #speaker {
    background: conic-gradient(
      from 159.43deg at -6.85% -57.38%,
      #fcaa51 0deg,
      #ff9522 360deg
    );
    z-index: 1 !important;
  }
  #shopping_bag {
    background: conic-gradient(
      from 159.25deg at -11.69% -72.64%,
      #ffa9dc 0deg,
      #ff90d8 360deg
    );
    z-index: 1 !important;
  }
  .count {
    font-size: 3.98rem;
    font-weight: 800;
    color: ${Colors.white};
  }
  .small_divider {
    height: 1.5px;
    width: 7.5rem;
    background-color: ${Colors.white};
  }

  .small_text {
    color: ${Colors.white};
    font-size: 13px;
    font-weight: 400;
  }
  .selfie_gif_image {
    height: ${(prop) => (prop.isMobile ? "20rem" : "26.2rem")};
    aspect-ratio: 1;
    transform: scalex(-1);
    position: absolute;
    z-index: -1;
    right: ${(prop) => (prop.isMobile ? "-3.5rem" : "-4.9rem")};
    top: ${(prop) => (prop.isMobile ? "-1rem" : "-4rem")};
  }
  .speaker_gif_image {
    height: ${(prop) => (prop.isMobile ? "17rem" : "19.9rem")};
    aspect-ratio: 1;
    position: absolute;
    right: ${(prop) => (prop.isMobile ? "-1rem" : "-0.8rem")};
    top: ${(prop) => (prop.isMobile ? "-0.2rem" : "-2rem")};
    z-index: -1 !important;
  }
  .shoppingBag_gif_image {
    height: ${(prop) => (prop.isMobile ? "14rem" : "18rem")};
    aspect-ratio: 1;
    transform: scalex(-1);
    position: absolute;
    right: ${(prop) => (prop.isMobile ? "-0.6rem" : "-0.5rem")};
    top: ${(prop) => (prop.isMobile ? "1rem" : "-1rem")};
    z-index: -1 !important;
  }
`;
