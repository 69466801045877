import { Spin } from "antd";
import React from "react";
import { useSelector } from "react-redux";
import { Navigate, Outlet } from "react-router-dom";

const ProtectedRoute = ({ children, isAuth }) => {
  const user = useSelector((state) => state.user);

  if (user?.isLoading === false) {
    if (user?.email === "") {
      return <Navigate to="/login" replace={true} />;
    } else {
      return <Outlet />;
    }
  } else {
    return (
      <div className="flexCenter" style={{ height: "100vh" }}>
        <Spin size={85} />
      </div>
    );
  }
};

export default ProtectedRoute;
