import { Typography } from "@mui/material";
import React from "react";
import { ServiceCardData } from "../../../../config/Data";
import useViewport from "../../../../viewport/useViewport";
import ServiceCard from "../../../card/ServiceCard";
import { ServiceWrapper } from "./Style";

const ServiceOverview = () => {
  const { isMobile, isTablet } = useViewport();
  return (
    <ServiceWrapper
      name="about"
      isMobile={isMobile}
      isTablet={isTablet}
      className="mt2"
    >
      <div
        style={{
          padding: isMobile ? "1rem 1rem" : "1rem 1rem",
          width: !isMobile && "62%",
        }}
      >
        <Typography
          className="fontLato service_heading"
          lineHeight={isMobile && "2.7rem"}
        >
          Services Overview
        </Typography>
        <Typography className="service_sub_heading fontLato">
          At Ingenkart, we drive brand growth through strategic influencer
          campaigns.
          <br /> We offer influencer research, content creation, and performance
          tracking to deliver real results.
        </Typography>
      </div>
      <div
        className={
          isMobile
            ? "info_card_cotainer flexCenter"
            : "info_card_cotainer flexBetween"
        }
      >
        {ServiceCardData?.map((item, i) => {
          return <ServiceCard item={item} key={i} />;
        })}
      </div>
    </ServiceWrapper>
  );
};

export default ServiceOverview;
