import { Button, Col, Input, Row, Typography, message } from "antd";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import useViewport from "../../viewport/useViewport";
import NormalLabel from "../../components/inputLabel/NormalLabel";
import { deleteUserAccountAPI } from "../../functions/user";
import { useLocation } from "react-router-dom";

// A custom hook that builds on useLocation to parse
// the query string for you.
function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}
const DeleteUserAccount = () => {
  const { isMobile, isTablet } = useViewport();
  const [userValues, setUserValues] = useState({
    email: "",
    phone: "",
  });
  const query = useQuery();
  useEffect(() => {
    localStorage.setItem("USER_ID_DELETE", JSON.stringify(query.get("userId")));
  }, []);
  const deleteMyAccount = () => {
    const myValues = { ...userValues, userId: query.get("userId") };
    if (window.confirm("Are you sure you want to delete your account?")) {
      deleteUserAccountAPI(myValues)
        .then((res) => {
          message.success("Your account has been dleted.");
          message.info(
            "We soft delete your account, so that you can retrieve it within 30 days."
          );
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };
  return (
    <Wrapper className="flexCenter" isMobile={isMobile} isTablet={isTablet}>
      <div className="form_container">
        <Row gutter={[16, 24]}>
          <Col span={24}>
            <Typography.Text>
              Fill out this account deletion form with your correct account
              linked mail and phone number.We will process your request within
              30 days and delete your account. We only collect your Name, Email
              Address,Date of birth, Gender and Phone number. Upon your account
              deletion we will delete all the data we have collected.
            </Typography.Text>
          </Col>
          <Col span={24}>
            <NormalLabel title="Email" identifier="email" />
            <Input
              size="large"
              placeholder="Email linked with your account"
              name="email"
              value={userValues?.email}
              onChange={(e) =>
                setUserValues({ ...userValues, email: e.target.value })
              }
            />
          </Col>
          <Col span={24}>
            <NormalLabel title="Phone Number" identifier="phone" />
            <Input
              size="large"
              placeholder="Phone Number linked with your account"
              name="phone"
              value={userValues?.phone}
              onChange={(e) =>
                setUserValues({ ...userValues, phone: e.target.value })
              }
            />
          </Col>
          <Col span={24}>
            <Button
              onClick={deleteMyAccount}
              disabled={userValues?.email === "" || userValues?.phone === ""}
            >
              Delete Account
            </Button>
          </Col>
        </Row>
      </div>
    </Wrapper>
  );
};

export default DeleteUserAccount;

const Wrapper = styled.div`
  min-height: 100vh;
  .form_container {
    width: ${(prop) => (prop.isMobile ? "98%" : prop.isTablet ? "80%" : "45%")};
  }
`;
