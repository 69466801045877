import styled from "styled-components";
import { Colors } from "../../../../config/Colors";
import { BorderRadius } from "../../../../config/Values";
export const LocationWrapper = styled.div`
  max-width: 100%;
  min-height: ${(prop) => (prop.isTablet ? "20vh" : "60vh")};
  max-height: ${(prop) => prop.isTablet && "80vh"};
  height: 100%;
  background-color: ${Colors.white};
  border-radius: ${BorderRadius.largeRadius};
  overflow: hidden;
  /* flex: 1; */
  position: relative;
  /* background-color: red; */

  .location_image {
    width: 100%;
    min-height: 107vh;
    height: 100%;
    border-radius: ${BorderRadius.largeRadius};
    z-index: 1;
    /* background-color: red; */
    object-fit: cover;
    margin-top: -10rem;
  }
  .map-container {
    /* height: 400px; */
  }
  .action_info_container {
    width: 96%;
    min-height: ${(prop) => (prop.isMobile ? "12.408rem" : "13.418rem")};
    max-height: ${(prop) => (prop.isMobile ? "14.408rem" : "17.418rem")};
    background-color: ${Colors.white};
    border-radius: ${BorderRadius.intermediateRadius};
    border: 1px solid rgba(0, 0, 0, 0.15);
    z-index: 2;
    position: absolute;
    bottom: 0.5rem;
    align-self: center;
    overflow: hidden;
    cursor: pointer;
    text-decoration: none;
  }
  .company_name {
    font-size: 1.375rem;
    font-weight: ${(prop) => (prop.isMobile ? "500" : "400")};
    color: ${Colors.black};
  }
  .hindi {
    font-size: 0.9rem;
    color: ${Colors.black};
  }
  .rating_container {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 0 1rem;
  }
  .rating_number {
    color: ${Colors.ratingGrey};
    font-size: 0.89rem;
    font-weight: 500;
  }
  .rating_star {
    font-size: 0.89rem;
    margin-left: 0.6rem;
  }
  .rating_text {
    font-size: 0.875rem;
    margin-left: 0.6rem;
    color: ${Colors.reviewBlue};
    font-weight: 500;
  }
  .divider {
    width: 100%;
    height: 1px;
    background: ${Colors.greyD9};
    mix-blend-mode: normal;
    transform: matrix(1, 0, 0, -1, 0, 0);
    margin: 1rem 0 0.5rem 0;
  }
  .gMap_option_image {
    width: 100%;
    height: auto;
    object-fit: contain;
    object-position: bottom !important;
  }
`;
