import { configureStore } from "@reduxjs/toolkit";
import userReducer from "./currentUserSlice";
import drawerReducer from "./navDrawerSlice";
import youTubeTokenReducer from "./youtubeSlice";

export default configureStore({
  reducer: {
    user: userReducer,
    drawerStatus: drawerReducer,
    youTubeToken: youTubeTokenReducer,
  },
});
