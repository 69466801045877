import * as yup from "yup";
export const SubscribeNewsletterSchema = yup.object().shape({
  email: yup
    .string()
    .email("Invalid email address")
    .required("Email is required"),
});

export const ContactUsSchema = yup.object().shape({
  email: yup
    .string()
    .email("Invalid email address")
    .required("Email is required"),
  name: yup.string().required("Name is required"),
  companyName: yup.string(),
  phoneNumber: yup.string().required("Phone number is required"),
  message: yup.string().required("Message is required"),
});
