import { Typography } from "@mui/material";
import React from "react";
import { ImageList } from "../../../../config/ImageList";
import useViewport from "../../../../viewport/useViewport";
import { SuccessWrapper } from "./Style";
const PartOfSuccess = () => {
  const { isMobile } = useViewport();
  return (
    <SuccessWrapper isMobile={isMobile} className="other-element" name="about">
      {/* <img
        alt="rectangle vector"
        src={ImageList.Starry_Vector_9}
        className="starry_vector"
      /> */}
      <div className="flexCenter">
        <img
          data-aos="zoom-in-down"
          data-aos-offset="-10"
          data-aos-duration="850"
          src={ImageList.Ingenkart_Text_Logo}
          alt="ingenkart logo"
          className="logo"
        />
      </div>
      <Typography
        className="fontLato mt1 success_heading"
        data-aos="fade"
        data-aos-duration="900"
        lineHeight={isMobile ? "4.7rem" : "5.8rem"}
      >
        Part of your
        <br /> success
      </Typography>
      <div
        className="text_container"
        data-aos="fade"
        data-aos-duration="850"
        style={{ marginTop: isMobile && "4rem" }}
      >
        <Typography className="fontLato mtp4 success_text">
          At our core, we believe in the power of authentic influencer
          marketing. That's why we prioritise building relationships between
          brands and influencers that are built on mutual trust and respect. Our
          platform ensures that influencers are compensated fairly for their
          work, and that brands receive high-quality content that resonates with
          their target audience.
        </Typography>
      </div>
    </SuccessWrapper>
  );
};

export default PartOfSuccess;
