import { Typography } from "@mui/material";
import React from "react";
import { FeaturesData } from "../../../../config/Data";
import useViewport from "../../../../viewport/useViewport";
import { FeaturesWrapper } from "./Style";

const Features = () => {
  const { isMobile } = useViewport();
  const SingleFeature = ({ imageUrl, title, subTitle }) => {
    return (
      <div className="single_feature_container flexCenter">
        <img src={imageUrl} alt={title} className="feature_image" />
        <Typography className="fontLato feature_heading mt1">
          {title}
        </Typography>
        <Typography className="fontLato feature_sub_heading mt1">
          {subTitle}
        </Typography>
      </div>
    );
  };
  return (
    <FeaturesWrapper className="mt2" isMobile={isMobile} name="faq">
      <Typography className="heading fontLato">
        <span style={{ fontWeight: "700" }}>Understand</span> why we are a
        perfect fit.
      </Typography>
      <Typography className="sub_heading fontLato mt1">
        Our platform makes it easy for brands to
        <span className="gradient_text"> find and connect</span> with
        influencers who align with their {!isMobile && <br />} brand values and
        goals.
      </Typography>
      <div
        className="mt3 flexCenter"
        style={{ width: "100%", flexWrap: "wrap" }}
      >
        {FeaturesData?.map((item, i) => {
          return <SingleFeature key={i} {...item} />;
        })}
      </div>
    </FeaturesWrapper>
  );
};

export default Features;
