import styled from "styled-components";
import { Colors } from "../../../config/Colors";
import { BorderRadius, Gradient } from "../../../config/Values";

export const CardWrapper = styled.div`
  width: ${(prop) => (prop.isMobile ? "100%" : "20rem")};
  height: 29.5rem;
  background-color: ${Colors.white};
  border-radius: ${BorderRadius.largeRadius};
  /* padding: 1rem; */
  margin-bottom: ${(prop) => prop.isTablet && "2rem"};
  position: relative;
  overflow: hidden;
  .card_bg {
    overflow: hidden;
    border-radius: ${BorderRadius.largeRadius};
  }
  .add_icon_container {
    height: 2.5rem;
    width: 100%;
    position: absolute;
    right: 10px;
    top: 1rem;
    display: flex;
    /* align-items: center; */
    justify-content: flex-end;
    padding-right: 0.45rem;
  }
  .icon_button {
    height: 2rem;
    width: 2rem;
  }
  .icon_button.add {
    background: ${Gradient.primaryRadial};
    color: ${Colors.white};
  }

  .icon:hover {
    transform: scale(1.2);
  }
  .icon_button.cross {
    background: ${Gradient.plainWhite};
    color: ${Colors.purple};
  }
  /* heading */
  .card_heading {
    font-weight: 700;
    font-size: 1.2rem;
    color: ${Colors.black};
    text-align: center;
    /* width: max-content; */
    transform: ${(prop) => !prop.isAdd && "translateX(-12%)"};
    transition: transform 0.4s linear;
    color: ${(prop) => (prop.isAdd ? Colors.black : Colors.white)};
  }
  .mobile_image {
    width: auto;
    height: 22rem;
    opacity: ${(prop) => (prop.isAdd ? 1 : 0)};
    transform: ${(prop) => !prop.isAdd && "translateY(15%)"};
    transition: all 0.3s linear;
  }
  .content_container {
    z-index: 100;
    position: absolute;
    top: 7rem;
    padding: 1rem;
    color: ${Colors.white};
  }
  .checkmark {
    width: 1.438rem;
    aspect-ratio: 1;
    margin-right: 0.6rem;
  }
  .checkmark_content {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
`;
