import "./App.css";
import React, { useEffect } from "react";
import ViewportProvider from "./viewport";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import HomeScreen from "./pages/home";
import Navbar from "./components/nav/Navbar";
import AboutScreen from "./pages/about";
import ContactScreen from "./pages/contact";
import InfluencerScreen from "./pages/influencers";
import PageNotFound from "./PageNotFound";
import Aos from "aos";
import Faq from "./pages/faq";
import GoogleForm from "./pages/gForm/GoogleForm";
import MyFooter from "./components/footer";
import ScrollTop from "./components/ScrollTop";
import PrivacyPolicy from "./pages/policies/PrivacyPolicy";
import Terms from "./pages/policies/Terms";
import InstagramAuthorize from "./pages/insta";
import YouTubeAuthorize from "./pages/youtube/YouTubeAuthoroze";
import InstagramThank from "./pages/insta/InstagramThank";
import YoutubeThank from "./pages/youtube/YouTubeThank";
import Login from "./pages/auth/Login";
import { onAuthStateChanged } from "firebase/auth";
import { checkPhoneNumber } from "./functions/auth";
import { getAsyncUser, noUser } from "./redux/currentUserSlice";
import { message } from "antd";
import { auth } from "./firebaseConfig";
import ProtectedRoute from "./components/route/ProtectedRoute";
import ProfileWall from "./pages/dashboard/ProfileWall";
import RedirectionComponent from "./components/RedirectionComponent";
import DeleteUserAccount from "./pages/user/DeleteUserAccount";

const App = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const userRed = useSelector((state) => state.user);
  useEffect(() => {
    Aos.init();
  }, [dispatch]);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        const idToken = await user.getIdTokenResult();
        console.log("APP JS USER FROM FIREBASE==>", user);
        if (await checkPhoneNumber(user?.uid)) {
          dispatch(getAsyncUser(idToken.token));
        } else {
          dispatch(noUser());
          message.error("Server Error.");
        }
      } else {
        dispatch(noUser());
        console.log(user);
      }
    });
    return () => unsubscribe();
  }, [dispatch]);
  // if user is present than redirect user from login page to home page
  useEffect(() => {
    if (location.pathname === "/login") {
      navigate("/profile", { replace: true });
    }
  }, [userRed]);

  return (
    <ViewportProvider>
      <ScrollTop />
      <RedirectionComponent />
      <div className="flexCenter">
        <Navbar />
      </div>
      <Routes>
        <Route path="/" element={<HomeScreen />} />
        <Route path="/about" element={<AboutScreen />} />
        <Route path="/contact" element={<ContactScreen />} />
        <Route path="/influencer" element={<InfluencerScreen />} />
        <Route path="/faq" element={<Faq />} />
        <Route path="/privacy" element={<PrivacyPolicy />} />
        <Route path="/terms" element={<Terms />} />
        <Route path="/register/influencer" element={<GoogleForm />} />
        <Route path="/auth/insta" element={<InstagramAuthorize />} />
        <Route path="/instagram" element={<InstagramThank />} />
        <Route path="/auth/youtube" element={<YouTubeAuthorize />} />
        <Route path="/youtube" element={<YoutubeThank />} />
        <Route path="/user/delete/account" element={<DeleteUserAccount />} />

        {/* auth */}
        <Route path="/login" element={<Login />} />
        <Route element={<ProtectedRoute />}>
          <Route path="/profile" element={<ProfileWall />} />
        </Route>
        <Route path="*" element={<PageNotFound />} />
      </Routes>
      <MyFooter />
    </ViewportProvider>
  );
};

export default App;
