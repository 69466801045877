import styled from "styled-components";
import { Colors } from "../../config/Colors";
import { BorderRadius } from "../../config/Values";

export const ContactFormWrapper = styled.div`
  width: 100%;
  flex: 1;
  min-height: 60vh;
  background-color: ${Colors.white};
  border-radius: ${BorderRadius.largeRadius};
  padding: ${(prop) => (prop.isMobile ? "2rem 1.3rem" : "2rem 3rem")};
  z-index: 1;
  position: relative;
  /* background-color: yellow; */

  .message_curve_image {
    position: absolute;
    right: 0;
    top: 0;
    z-index: 0;
    width: 12.579rem;
    height: 12.262rem;
  }
  .heading {
    font-weight: 700;
    font-size: 2.688rem;
  }
  .sub_heading {
    font-weight: 400;
    font-size: 1.25rem;
  }
  .input_container {
    height: 2.499rem;
    border-radius: ${BorderRadius.smallRadius};
  }
  .submit_button {
    text-transform: capitalize;
    font-size: 12px;
    border-radius: ${BorderRadius.smallRadius};
    background-color: ${Colors.black};
    font-weight: 400;
    color: ${Colors.white} !important;
  }
  .submit_button:hover {
    background-color: ${Colors.black};
  }
`;
