import React, { useEffect, useState } from "react";
import { ProfileWrapper } from "./Style";
import useViewport from "../../viewport/useViewport";
import { useDispatch, useSelector } from "react-redux";
import { Avatar, Card, message } from "antd";
import { Button } from "@mui/material";
import { getInstaPost } from "../../functions/instagram";
import { signOut } from "firebase/auth";
import { auth } from "../../firebaseConfig";
import { logoutUser } from "../../redux/currentUserSlice";
import { useNavigate } from "react-router-dom";
const { Meta } = Card;
const ProfileWall = () => {
  const { isMobile } = useViewport();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector((state) => state.user);
  const [instaData, setInstaData] = useState(null);
  useEffect(() => {
    if (user) {
      getInstaPosts();
    }
  }, [user]);
  const logout = async () => {
    await signOut(auth)
      .then(() => {
        console.log("User signed out.");
        dispatch(logoutUser());
      })
      .catch((error) => {
        console.error(error);
      });
  };
  const getInstaPosts = async () => {
    await getInstaPost(user?._id, user?.token)
      .then((res) => {
        setInstaData(res.data.posts);
      })
      .catch((err) => {
        console.log(err);
        message.error(err.response.data.message);
      });
  };
  return (
    <ProfileWrapper ismobile={isMobile} className="flexCenter">
      <div className="user_info_card flexCenter">
        <div
          className="flexBetween"
          style={{
            justifyContent: "flex-end",
            width: "100%",
            paddingRight: 10,
          }}
        >
          <Button onClick={logout}>Logout</Button>
          <Button
            variant="outlined"
            onClick={() => navigate("/auth/youtube")}
            style={{
              textTransform: "capitalize",
            }}
          >
            YouTube Connect{" "}
          </Button>
        </div>
        <Avatar
          size={150}
          src={user?.profilePicture?.url}
          alt={user?.name}
          className="user_avatar"
        />
        <h3 className="mt1">{user?.name} </h3>
        <h4 className="mt1">{user?.email} </h4>
        <h4 className="mt1">{user?.phone} </h4>
        <Button
          variant="contained"
          className="connect_button mt1"
          href="https://api.instagram.com/oauth/authorize?client_id=3469947243229022&redirect_uri=https://www.ingenkart.com/instagram&scope=user_profile,user_media&response_type=code"
        >
          Connect Instagram
        </Button>
      </div>
      {/* insta posts */}
      {/* {JSON.stringify(instaData[1], 2, 4)} */}
      <div
        className="flexCenter"
        style={{
          flexWrap: "wrap",
          gap: 40,
          marginBottom: 100,
          marginTop: 100,
        }}
      >
        {instaData?.map((item, i) => {
          return (
            <Card
              key={i}
              hoverable
              style={{
                width: 240,
              }}
              cover={<img alt="example" src={item?.media_url} />}
            >
              <Meta title={item?.caption} />
            </Card>
          );
        })}
      </div>
    </ProfileWrapper>
  );
};

export default ProfileWall;
