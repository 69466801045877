import axios from "axios";

export const currentUser = async (authtoken) => {
  return await axios.post(
    `${process.env.REACT_APP_API}/auth/current-user-phone`,
    {},
    {
      headers: {
        authorization: authtoken,
      },
    }
  );
};
export const checkPhoneNumber = async (number) => {
  const { data } = await axios.post(
    `${process.env.REACT_APP_API}/auth/check-existing-phone`,
    {
      phone: number,
    },
    {}
  );
  return data.isExisting;
};

export const phoneVerification = async (uuid, phone) => {
  return await axios.post(
    `${process.env.REACT_APP_API}/auth/send-phone-verification-otp`,
    {
      uuid,
      phone,
    },
    {}
  );
};

export const verifyNumberOtp = async (uuid, otp, phone) => {
  console.log("MY PHONE====>>", phone);
  return await axios.post(`${process.env.REACT_APP_API}/auth/verify-otp`, {
    uuid,
    otp,
    phone,
  });
};
