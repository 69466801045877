import styled from "styled-components";
import { ImageList } from "../../../config/ImageList";

export const MobileWrapper = styled.div`
  width: 37rem;
  background: url(${ImageList.Ellipse_Bg});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  position: relative;
  bottom: 0 !important;
  .phoneImage {
  }
  .mobile_side {
    width: auto;
    height: 21.425rem;
  }
  .mobile_center {
    width: auto;
    height: 24.08rem;
  }
`;
