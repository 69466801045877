import { Typography } from "@mui/material";
import React from "react";
import { ImageList } from "../../../../config/ImageList";
import useViewport from "../../../../viewport/useViewport";
import { StatsWrapper } from "./Style";
import CountUp from "react-countup";
import { Col, Row } from "antd";

const StatsCount = () => {
  const { isMobile, isTablet } = useViewport();

  return (
    <StatsWrapper
      className={isTablet ? "mb1 flexCenter" : "flexBetween mtp6"}
      isMobile={isMobile}
      isTablet={isTablet}
      name="influencers"
    >
      <Row
        gutter={[16, 24]}
        style={{
          width: "100%",
          margin: 0,
        }}
        justify="space-between"
        className="mt1 flexBetween"
        name="contact"
      >
        {/* ======================================= */}
        {/* Selfie */}
        {/* ======================================= */}
        <Col xs={24} sm={24} md={12} lg={8}>
          <div className="stat_card_container" id="selfie">
            <Typography className="count fontLato">
              <CountUp
                end={5000}
                start={0}
                duration={3}
                enableScrollSpy
                scrollSpyOnce={true}
              />
              +
            </Typography>
            <div className="small_divider" />
            <img
              src={ImageList.Selfie_Gif}
              alt="Selfie"
              className="selfie_gif_image"
            />
            <Typography className="small_text fontLato mtp6">
              Active Influencers
            </Typography>
          </div>
        </Col>
        {/* ======================================= */}
        {/* speaker */}
        {/* ======================================= */}
        <Col xs={24} sm={24} md={12} lg={8}>
          <div className="stat_card_container" id="speaker">
            <Typography className="count fontLato">
              <CountUp
                end={200}
                start={0}
                duration={3}
                enableScrollSpy
                scrollSpyOnce={true}
              />
              M+
            </Typography>
            <div className="small_divider" />
            <img
              src={ImageList.Speaker_Gif}
              alt="Selfie"
              className="speaker_gif_image"
            />
            <Typography className="small_text fontLato mtp6">
              Total follower reach
            </Typography>
          </div>
        </Col>
        {/* ======================================= */}
        {/* shopping bag */}
        {/* ======================================= */}

        <Col xs={24} sm={24} md={12} lg={8}>
          <div className="stat_card_container" id="shopping_bag">
            <Typography className="count fontLato">
              <CountUp
                end={100}
                start={0}
                duration={2}
                enableScrollSpy
                scrollSpyOnce={true}
              />
              +
            </Typography>
            <div className="small_divider" />
            <img
              src={ImageList.Shoping_Bag_Gif}
              alt="Selfie"
              className="shoppingBag_gif_image"
            />
            <Typography className="small_text fontLato mtp6">
              Brands trust us
            </Typography>
          </div>
        </Col>
      </Row>
    </StatsWrapper>
  );
};

export default StatsCount;
