export const Colors = {
  black: "#000000",
  black70: "rgba(0,0,0,0.7)",
  black40: "rgba(0,0,0,0.4)",
  black30: "rgba(0,0,0,0.3)",
  black80: "rgba(0,0,0,0.8)",
  black90: "rgba(0,0,0,0.9)",
  black64: "#646464",
  white: "#ffffff",
  white80: "rgba(255,255,255,0.8)",
  white50: "rgba(255,255,255,0.5)",
  lightGreen: "#2DCE89",
  textGreen: "#1AAE6F",
  alertRed: "#F5365C",
  faintedGreen: "#B0EED3",
  faintedPink: "#FEE6E0",
  lightPurple: "#5E72E4",
  purple: "#4764FF",
  textGrey: "#67748E",
  activeBg: "#f6f9fc",
  lightGrey: "#cecece",
  textBlack: "#344767",
  cardText: "#848892",
  bsDark: "#212529",
  lightOrange: "#FB6340",
  faintedOrange: "#FEB18D",
  tableText: "#8392AB",
  faintedWhiteBg: "#F8F9FA",
  switchBg: "rgba(33,37,41,.1)",
  inputBg: "rgba(217,217,217,0.2)",
  messageBg: "#F1F7FF",
  searchBg: "#F0F2F5",
  greyC9: "#C9C9C9",
  greyD9: "#D9D9D9",
  greyF7: "#F7F7F7",
  greyF5: "#F5F5F5",
  greyD9A: "#d9d9d966",
  greyDashedBorder: "#d3d6da",
  tagText: "rgba(52,72,95,0.7)",
  tagGreenBg: " rgba(38, 163, 38, 0.2)",
  darkGreen: "#008000",
  tagGreyBg: "#EFEFEF",
  ratingGrey: "#70757A",
  reviewBlue: "#1A73E8",
};
