import axios from "axios";

export const getAccessToken = async (userId, code) => {
  return await axios.get(
    `${process.env.REACT_APP_API}/auth/insta-access-token?userId=${userId}&code=${code}`,
    {}
  );
};

export const getInstaPost = async (userId, authtoken) => {
  return await axios.get(
    `${process.env.REACT_APP_API}/influencer/insta-post-details?userId=${userId}`,
    {
      headers: {
        authorization: authtoken,
      },
    }
  );
};

export const getYoutubeAccessTokenAPI = async (userId, code) => {
  return await axios.get(
    `${process.env.REACT_APP_API}/auth/youtube-access-token?userId=${userId}&code=${code}`,
    {}
  );
};
