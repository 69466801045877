import * as yup from "yup";
// step 1 in create campaign form
export const StepOneSchema = yup.object().shape({
  campaignName: yup.string().required("Campaign name is required"),
  campaignPeriod: yup.object().shape({
    startDate: yup.string().required("Start date is required"),
    endDate: yup.string().required("End date is required"),
  }),
  campaignPlatform: yup.object().shape({
    platformName: yup.string().required("Platform name is required"),
    platformLogo: yup.string(),
  }),
  deliverableType: yup
    .array()
    .min(1, "Select one deliverable")
    .of(yup.string().required("Select one deliverable")),
  dos: yup
    .array()
    .min(1, `At least one Do's must be included`)
    .of(yup.string().required()),
  donts: yup
    .array()
    .min(1, `At least one Do's must be included`)
    .of(yup.string().required(`Do's is required field`)),
});

// step 2 in create campaign form
export const StepTwoSchema = yup.object().shape({
  campaignCategories: yup
    .array()
    .min(1, "Select min 1 category")
    .required("Select at least one category"),
  age: yup.object().shape({
    min: yup
      .number()
      .min(14, "Age must be above 14")
      .required("Select minimum age"),
    max: yup.number().when("min", {
      is: (val) => val !== null,
      then: yup.number().moreThan(yup.ref("min")),
    }),
  }),
  isBarter: yup.boolean(),
  influencerBudget: yup.object().shape({
    min: yup
      .number()
      .nullable()
      .when("isBarter", {
        is: false,
        then: yup.number().required("Select minimum budget"),
      }),
    max: yup
      .number()
      .nullable()
      .when("min", {
        is: (val) => val !== null,
        then: yup.number().moreThan(yup.ref("min")),
      }),
  }),
  followersRange: yup.object().shape({
    min: yup.number().required("Select min influencer"),
    max: yup.number().when("min", {
      is: (val) => val !== null,
      then: yup.number().moreThan(yup.ref("min")),
    }),
  }),
  gender: yup.string().required("Select gender"),
  influencerRequired: yup.number().required("Influencer count is required"),
});

// step 3 in create campaign form
export const StepThreeSchema = yup.object().shape({
  campaignBanner: yup.string().required("Upload banner image"),
  referenceImage: yup.array(),
  productReference: yup.array(),
  referenceVideo: yup.array(),
});

// login form

export const LoginSchema = yup.object().shape({
  phoneNumber: yup
    .string()
    .matches(
      /^(?:(?:\+|0{0,2})91(\s*[\-]\s*)?|[0]?)?[6789]\d{9}$/,
      "Invalid phone number"
    )
    .required("Phone number is required"),
});

// schema for Create New User
export const CreateUserSchema = yup.object().shape({
  email: yup.string().email().required("Email is required"),
  phone: yup.string().required("Phone number is required"),
  firstName: yup.string().required("First Name is required"),
  lastName: yup.string().optional(),
  role: yup.string().required("Role is required"),

  DOB: yup.string().optional(),
  categories: yup
    .array()
    .min(1, "Select at least one category")
    .required("Category is required"),

  profilePicture: yup
    .object()
    .shape({
      reference: yup.string().optional(),
      url: yup.string().optional(),
      isDefault: yup.boolean().optional(),
    })
    .optional(),
  about: yup.string().optional(),

  tagLine: yup.string().when("role", {
    is: "brand",
    then: yup.string().required(),
    otherwise: yup.string().optional(),
  }),
  headQuarters: yup.string().optional(),
  linkedIn: yup.string().optional(),

  gender: yup.string().when("role", {
    is: "influencer",
    then: yup.string().required(),
    otherwise: yup.string().optional(),
  }),
  age: yup.number().when("role", {
    is: "influencer",
    then: yup.number().required(),
    otherwise: yup.number().optional(),
  }),
  state: yup.string().required("State is required"),
  city: yup.string().required("City is required"),
  availability: yup.string().when("role", {
    is: "influencer",
    then: yup.string().required(),
    otherwise: yup.string().optional(),
  }),
  websiteUrl: yup.string(),
  instagramProfile: yup.string(),
  instagramFollowing: yup.string().when("role", {
    is: "influencer",
    then: yup.string().required("Following count is required"),
    otherwise: yup.string().notRequired(),
  }),
  instagramFollowers: yup.string().when("role", {
    is: "influencer",
    then: yup.string().required("Follower count is required"),
    otherwise: yup.string().notRequired(),
  }),
  instagramDP: yup.string(),
  isCelebrity: yup.boolean(),
  barterAvailability: yup.boolean().required(),
  budget: yup.object().shape({
    min: yup
      .number()
      .min(0, "Minimum budget must be 0")
      .required("Select minimum budget"),
    max: yup.number().when("min", {
      is: (val) => val !== null,
      then: yup.number().moreThan(yup.ref("min")),
    }),
  }),
});

// create reels schema

export const CreateReelsSchema = yup.object().shape({
  videoUrl: yup.string().required("Video must be uploaded"),
  userId: yup.string().required("Login first, as no user is found"),
  coverImageUrl: yup.string().required("Cover image must be uploaded"),
  displayName: yup.string().required("Display name cannot be empty"),
  description: yup.string().required("Description cannot be empty"),
});

export const CreateStorySchema = yup.object().shape({
  storyType: yup.string().required("Select the story type"),
  backgroundColor: yup.string(),
  textColor: yup.string(),
  textMessage: yup.string(),
  storyMediaUrl: yup.string(),
  storyMediaThumbnail: yup.string(),
  userId: yup.string().required("Login first, as no user is found"),
});

// transfer credit

export const TransferCreditSchema = yup.object().shape({
  campaignId: yup.string().required("CampaignId is required"),
  brandId: yup.string().required("BrandId is required"),
  credits: yup.number().required("Credit is required"),
  ingenkartCommission: yup.number().required("Commission is required"),
});
