export const PrivacyPolicies = [
  {
    heading: "Privacy Policy",
    textData: `Ingenkart respects your privacy and is committed to protecting the personal information of its users. We believe that you have a right to know our practices regarding the information we collect while accessing the Ingenkart influencer marketing platform (“Platform”). This Privacy Policy (the “Policy”) is a legally binding document between You and Ingenkart. The terms of this Policy will be effective upon your acceptance of the same (directly or indirectly in electronic form, by clicking on the “I accept” tab or by use of the Platform or by other means) and will govern the relationship between You and Ingenkart for your use of the Platform. Please read this policy carefully. By using the Platform, you indicate that you understand, agree and consent to this Policy. If you do not agree with the terms of this Policy, please do not use this Platform.`,
    textData1: `The terms used are not gender-specific.`,
    textData2: `Last Update: 13. March 2023`,
  },
  {
    heading: "Table of Contents",
    dataArr: [
      "Privacy Policy",
      "Information We Collect",
      "Use of Collected Information",
      "Sharing of Information",
      "Security Measures",
      "Cookies and Tracking Technologies",
      "Third-Party Links and Services",
      "Data Retention",
      "Your Rights",
      "Children's Privacy",
      "Updates to Privacy Policy",
      "Governing Law",
      "Contact Us",
    ],
  },
  {
    heading: "1. Information We Collect:",
    textData: `1.1. Personal Information: Ingenkart collects information from you when you create an account, use the Platform, or interact with Ingenkart in other ways. The information we collect may include:`,
    dataArr: [
      "Your name",
      "Your email address",
      "Your phone number",
      "Your social media handles",
      "Your demographic information (such as your age, gender, and location)",
      "Your interests",
      "Your content (such as your blog posts,social media posts, reels, videos, and photos)",
      "Your interactions with the Platform (such as the campaigns you apply to and the content you create)",
    ],
    textData5: `1.2. User-generated Content: We may collect and store the content you share on Ingenkart, including posts, messages, and other communications.`,
    textData6: `1.3. Usage Data: We automatically collect certain information about your interactions with the platform, such as your IP address, device information, and browsing activity.`,
  },
  {
    heading: "2. Use of Collected Information:",
    textData: "2.1. Ingenkart uses the information we collect to:",
    dataArr: [
      "Provide you with the Platform and its features",
      "Communicate with you",
      "Match you with brands that are looking for influencers to promote their products or services",
      "Track your earnings from campaigns",
      "Improve the Platform",
      "Comply with applicable laws and regulations",
    ],
    textData5:
      "2.2. We may use aggregated and anonymized data for analytical purposes, market research, and improving our platform.",
  },

  {
    heading: "3. Sharing of Information:",
    textData1:
      "3.1. We may share your personal information with brands and influencers involved in influencer marketing campaigns.",
    textData2:
      "3.2. We may share your information with trusted third-party service providers who assist us in delivering our services.",
    textData3:
      "3.3. We may disclose your information if required by law, to protect our rights, or to comply with a legal process.",
  },
  {
    heading: "4. Security Measures:",
    textData1:
      "4.1. We implement industry-standard security measures to protect your personal information from unauthorised access, disclosure, or alteration.",
    textData2:
      "4.2. However, please note that no method of data transmission over the internet or electronic storage is 100% secure.",
  },
  {
    heading: "5. Cookies and Tracking Technologies:",
    textData1:
      "5.1. We use cookies and similar tracking technologies to enhance your experience on Ingenkart and gather information about your usage patterns.",
    textData2:
      "5.2. You have the option to disable cookies through your browser settings, but this may affect your ability to use certain features of the platform.",
  },
  {
    heading: "6. Third-Party Links and Services:",
    textData1: `6.1. Ingenkart may contain links to third-party websites or services. We are not responsible for the privacy practices or content of those third parties.", BDSG), in particular with regard to the establishment, execution or termination of employment relationships as well as the consent of employees. Furthermore, data protection laws of the individual federal states may apply.`,
  },
  {
    heading: "7. Data Retention:",
    textData1:
      "7.1. We retain your personal information for as long as necessary to fulfil the purposes outlined in this Privacy Policy, unless a longer retention period is required or permitted by law.",
  },
  {
    heading: "8. Your Rights:",
    textData: "8.1. You have the right to:",
    dataArr: [
      "Access your information",
      "Request that we correct or delete your information",
      "Object to our processing of your information",
      "Request that we restrict our processing of your information",
      "Request that we port your information to another service",
      "Withdraw your consent to our processing of your information",
    ],
    textData5:
      "8.2. To exercise your rights or make any privacy-related requests, please contact us using the information provided below.",
  },
  {
    heading: "9. Children's Privacy:",
    textData1:
      "9.1. Ingenkart is not intended for use by individuals under the age of 13. We do not knowingly collect personal information from children.",
  },
  {
    heading: "10. Updates to Privacy Policy:",
    textData1:
      "10.1. We may update this Privacy Policy from time to time. We will notify you of any material changes through the platform or other means.",
  },
  {
    heading: "11. Governing Law",
    textData1: "11.1 This Privacy Policy is governed by the laws of India.",
  },
  {
    heading: "12. Contact Us:",
    textData:
      "If you have any questions, concerns, or requests regarding this Privacy Policy, please mail us at account@ingenkart.com.",
    textData1:
      "Please note that this Privacy Policy should be read in conjunction with our Terms and Conditions.",
  },
];
