import styled from "styled-components";
import { Colors } from "../../../../config/Colors";
import { BorderRadius, Gradient } from "../../../../config/Values";

export const ScreenShotWrapper = styled.div`
  width: 100%;
  min-height: ${(prop) => (prop.isTablet ? "50vh" : "80vh")};
  background: conic-gradient(
    from 154.38deg at 0% -28.9%,
    ${Colors.greyD9A} 0deg,
    #fff 360deg
  );
  border-radius: ${BorderRadius.largeRadius};
  padding: 2rem;
  position: relative;
  .app_scrrenshot_image {
    position: absolute;
    top: 0;
    right: 0;
    width: ${(prop) => (prop.isTablet ? "0%" : "58%")};
    height: 100%;
    border-radius: ${BorderRadius.largeRadius};
  }
  .heading {
    font-size: ${(prop) => (prop.isMobile ? "1.8rem" : "2.8rem")};
    font-weight: 700;
  }
  .radial_heading_text {
    background: ${Gradient.primaryRadial};
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    z-index: 1 !important;
  }
  .sub_heading {
    font-size: ${(prop) => (prop.isMobile ? "1rem" : "1.625rem")};
    font-weight: 400;
    max-width: ${(prop) => (prop.isTablet ? "100%" : "48%")};
  }
  .single_ponter_container {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    max-width: ${(prop) => (prop.isTablet ? "100%" : "48%")};
  }
  .checkmark_image {
    width: 1.017rem;
    aspect-ratio: 1;
    object-fit: cover;
    object-position: center;
  }
  .pointer_text {
    margin-left: 0.5rem;
    font-size: 14px;
  }
  .store_button_container {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
  }
  .store_badge {
    width: auto;
    height: 4.789rem;
    object-fit: cover;
    object-position: center;
  }
`;
