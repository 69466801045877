import styled from "styled-components";
import { Colors } from "../../../../config/Colors";
export const SuccessWrapper = styled.div`
  width: 100%;
  min-height: 78vh;
  z-index: 100;
  background-color: ${Colors.greyF5};
  padding-top: ${(prop) => (prop.isMobile ? "4rem" : "6rem")};
  padding-bottom: ${(prop) => (prop.isMobile ? 0 : "4rem")};
  .starry_vector {
    position: relative;
    top: ${(prop) => (prop.isMobile ? "-6rem" : "-9rem")};
    right: 0 !important;
    width: auto;
    height: 4rem;
    float: right;
  }
  .logo {
    width: auto;
    height: 2rem;
  }
  .success_heading {
    font-weight: 700;
    font-size: ${(prop) => (prop.isMobile ? "4rem" : "4.5rem")};
    text-align: center;
  }
  .text_container {
    max-width: 100%;
    padding: ${(prop) => (prop.isMobile ? "0 9%" : "0 21%")};
  }
  .success_text {
    font-weight: 400;
    font-size: ${(prop) => (prop.isMobile ? "0.85rem" : "1.75rem")};
    text-align: center;
    font-style: normal;
  }
`;
