import { Typography } from "@mui/material";
import React from "react";
import { ImageList } from "../../../../config/ImageList";
import useViewport from "../../../../viewport/useViewport";
import { ScreenShotWrapper } from "./Style";
import { message } from "antd";

const ScreenShots = () => {
  const { isMobile, isTablet } = useViewport();
  let Pointers = [
    "Reach a wider audience",
    "Build trust and credibility",
    "Increase brand awareness",
    "Boost sales and revenue",
    "Drive traffic to your website",
  ];
  const handleDownload = () => {
    const link = document.createElement("a");
    link.href = "https://play.google.com/store/apps/details?id=com.ingenkart";
    link.target = "_blank";
    link.rel = "noopener noreferrer";
    link.click();
  };
  return (
    <ScreenShotWrapper
      className="mt4"
      isMobile={isMobile}
      isTablet={isTablet}
      name="download"
    >
      <img
        src={ImageList.App_Screenshot_Image}
        alt="screenshot"
        className="app_scrrenshot_image"
      />
      <Typography className="fontLato heading">
        <span className="radial_heading_text">Supercharge</span> Your Brand{" "}
        {!isMobile && <br />} with Influencer Marketing!
      </Typography>
      <Typography className="fontLato sub_heading mtp5">
        By collaborating with influencers, brands can tap into their existing
        audiences and build trust and credibility with potential
        customers.Choose our platform to help you find and connect with the
        right influencers to take your brand to the next level!
      </Typography>
      <div className="mt2" style={{ paddingLeft: 10 }}>
        {Pointers?.map((item, i) => {
          return (
            <div className="single_ponter_container mtp6" key={i}>
              <img
                src={ImageList.Colored_checkmark_Image}
                alt="checkmark"
                className="checkmark_image"
              />
              <Typography className="fontLato pointer_text" variant="caption">
                {item}
              </Typography>
            </div>
          );
        })}
      </div>
      {/* download button */}
      <div className="store_button_container mt2">
        <img
          src={ImageList.Play_Store_Badge}
          alt="play store"
          className="store_badge"
          onClick={handleDownload}
          style={{ cursor: "pointer" }}
        />
        <img
          src={ImageList.App_Store_Badge}
          alt="app store"
          className="store_badge"
          onClick={() => message.info("Coming Soon!")}
          style={{ cursor: "pointer" }}
        />
      </div>
    </ScreenShotWrapper>
  );
};

export default ScreenShots;
