import { IconButton, Typography } from "@mui/material";
import React, { useState } from "react";
import { CardWrapper } from "./Style";
import { motion } from "framer-motion";
import { Icons } from "../../../config/IconsList";
import { BorderRadius, Gradient } from "../../../config/Values";
import { ImageList } from "../../../config/ImageList";
import useViewport from "../../../viewport/useViewport";
const ServiceCard = ({ item }) => {
  const { isMobile, isTablet } = useViewport();
  const [isAdd, setIsAdd] = useState(true);
  const handleAddToggle = () => {
    setIsAdd((prev) => !prev);
  };
  const transition = { duration: 0.3, ease: "linear" };
  return (
    <CardWrapper isAdd={isAdd} isMobile={isMobile} isTablet={isTablet}>
      {/* add button */}
      <div className="add_icon_container">
        <IconButton
          className={`icon_button ${isAdd ? "add" : "cross"}`}
          onClick={handleAddToggle}
        >
          <motion.div
            className="flexCenter"
            animate={isAdd ? { rotate: 0 } : { rotate: 45 }}
            transition={transition}
          >
            <Icons.AddIcon className="icon" />
          </motion.div>
        </IconButton>
      </div>
      {/* card content */}
      <motion.div
        className="card_bg"
        animate={
          isAdd
            ? {
                background: Gradient.plainWhite,
                height: "100%",
                width: "100%",
                borderRadius: BorderRadius.largeRadius,
              }
            : {
                background: Gradient.primaryBlueRadial,
                height: "100%",
                width: "100%",
                borderRadius: BorderRadius.largeRadius,
              }
        }
        transition={transition}
      >
        {/* first line of title */}
        <Typography
          className="card_heading mt3"
          style={{
            width: !isAdd && "fit-content",
            paddingLeft: !isAdd && "2.7rem",
          }}
        >
          {item.title1}
        </Typography>
        {/* second line of Title */}
        <Typography
          className="card_heading"
          style={{
            transform: !isAdd && "translateX(-21.5%)",
            width: !isAdd && "fit-content",
            paddingLeft: !isAdd && "3.4rem",
          }}
        >
          {item.title2}
        </Typography>
        {/* mobile image  */}
        <div className="flexCenter">
          <img src={item.image} alt={item.title1} className="mobile_image" />
        </div>
      </motion.div>
      {/* content which will be visible on gradient background */}
      {!isAdd && (
        <div
          className="content_container"
          data-aos="fade"
          data-aos-delay="200"
          data-aos-duration="500"
        >
          <Typography variant="caption" className="fontLato" fontSize="12px">
            {" "}
            {item.description}{" "}
          </Typography>
          {item?.pointers?.map((t, i) => {
            return (
              <div
                className="checkmark_content mt1"
                style={{
                  alignItems: "flex-start",
                }}
                key={i}
                data-aos="fade"
                data-aos-delay="300"
              >
                <img
                  src={ImageList.Checkmark_Image}
                  alt="checkmark"
                  className="checkmark"
                />
                <Typography className="fontLato" fontSize="12px">
                  {t}
                </Typography>
              </div>
            );
          })}
        </div>
      )}
    </CardWrapper>
  );
};

export default ServiceCard;
