import styled from "styled-components";

export const ProfileWrapper = styled.div`
  min-height: 100vh;
  position: relative;
  top: 4.3rem;
  flex-direction: column;
  .user_info_card {
    background: #f2f2f2;
    border-radius: 13px;
    min-height: 24rem;
    width: ${(prop) => (prop.ismobile ? "100%" : "50%")};
    flex-direction: column;
  }
  .connect_button {
    border-radius: 12px;
    text-transform: capitalize;
  }
`;
