import styled from "styled-components";
import { Colors } from "../../../../config/Colors";
import { BorderRadius, Gradient } from "../../../../config/Values";

export const FeaturesWrapper = styled.div`
  min-height: 80vh;
  width: ${(prop) => (prop.isMobile ? "100%" : "100%")};
  max-width: 100% !important;
  background-color: ${Colors.white};
  border-radius: ${BorderRadius.largeRadius};
  padding: 3rem 2rem;
  .heading {
    font-size: 2.063rem;
    font-weight: 400;
    text-align: center;
  }
  .sub_heading {
    font-size: 0.938rem;
    font-weight: 400;
    text-align: center;
  }
  .gradient_text {
    background: ${Gradient.primaryRadial};
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
  }
  .feature_heading {
    font-size: 1rem;
    font-weight: 600;
    text-align: center;
    /* background-color: yellow; */
  }
  .feature_sub_heading {
    font-weight: 400;
    text-align: center;
    font-size: 12px;
    /* background-color: red; */
  }
  .single_feature_container {
    width: 18.4rem;
    /* background-color: red; */
    flex-direction: column;
    color: ${Colors.black64};
    margin: 2rem;
  }
  .feature_image {
    width: 5.832rem;
    height: 5.832rem;
    object-fit: cover;
    /* background-color: blue; */
  }
`;
