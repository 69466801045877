import React from "react";
import ComingSoon from "../../components/comingSoon/ComingSoon";

const InfluencerScreen = () => {
  return (
    <div className="mt6 flexCenter">
      {" "}
      <ComingSoon />{" "}
    </div>
  );
};

export default InfluencerScreen;
