import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useLocation } from "react-router-dom";
import { ImageList } from "../../config/ImageList";
import { toggleNavDrawer } from "../../redux/navDrawerSlice";
import useViewport from "../../viewport/useViewport";
import { NavWrapper } from "./Style";
import Drawer from "@mui/material/Drawer";
import SideNav from "./SideNav";
import { Link } from "react-scroll";
import { Avatar } from "antd";
import { UserOutlined } from "@ant-design/icons";

const Navbar = () => {
  const { isMobile } = useViewport();
  const drawerStatus = useSelector((state) => state.drawerStatus);
  const dispatch = useDispatch();
  const location = useLocation();
  const closeDrawer = () => {
    dispatch(toggleNavDrawer({ isOpen: false }));
  };
  const openDrawer = () => {
    dispatch(toggleNavDrawer({ isOpen: true }));
  };

  return (
    <>
      <Drawer anchor="left" open={drawerStatus.isOpen} onClose={closeDrawer}>
        <SideNav />
      </Drawer>

      <NavWrapper className="flexBetween" isMobile={isMobile}>
        {/* logo */}
        {location.pathname === "/" ? (
          <Link
            to="home"
            smooth={true}
            duration={500}
            spy={true}
            exact="true"
            offset={-70}
            className="scroll_link"
            activeClass="activeClassName fontPoppins"
          >
            <img
              src={ImageList.IngenKartLogo}
              loading="lazy"
              alt="logo"
              className="logo_image"
            />
          </Link>
        ) : (
          <NavLink
            to="/"
            className={({ isActive }) =>
              isActive ? "activeClassName" : "inactiveClassName"
            }
          >
            <img
              src={ImageList.IngenKartLogo}
              loading="lazy"
              alt="logo"
              className="logo_image"
            />
          </NavLink>
        )}
        {/* navbar on desktop */}
        {!isMobile && (
          <div className="flexBetween">
            <Link
              to="about"
              smooth={true}
              duration={500}
              spy={true}
              exact="true"
              offset={-70}
              className="scroll_link"
              activeClass="activeClassName fontPoppins"
            >
              About
            </Link>
            <Link
              to="contact"
              smooth={true}
              duration={500}
              spy={true}
              exact="true"
              offset={-70}
              className="scroll_link"
              activeClass="activeClassName fontPoppins"
            >
              Contact
            </Link>
            <Link
              to="influencers"
              smooth={true}
              duration={500}
              spy={true}
              exact="true"
              offset={-70}
              className="scroll_link"
              activeClass="activeClassName fontPoppins"
            >
              Influencers
            </Link>
            <Link
              to="faq"
              smooth={true}
              duration={500}
              spy={true}
              exact="true"
              offset={-70}
              className="scroll_link"
              activeClass="activeClassName fontPoppins"
            >
              FAQ
            </Link>
            <Link
              to="download"
              smooth={true}
              duration={500}
              spy={true}
              exact="true"
              offset={-70}
              className="scroll_link downloadButton fontPoppins"
              activeClass="activeClassName downloadButton fontPoppins"
            >
              Download
            </Link>
            <NavLink to="/profile">
              <Avatar icon={<UserOutlined />} />
            </NavLink>
          </div>
        )}
        {isMobile && (
          <Link
            to="download"
            smooth={true}
            duration={700}
            spy={true}
            exact="true"
            offset={-70}
            className="scroll_link downloadButtonMobile fontPoppins"
            activeClass="activeClassName downloadButtonMobile fontPoppins"
          >
            Download
          </Link>
        )}
        {isMobile && (
          <img
            src={ImageList.Hamburger_Icon}
            alt="hamburger"
            className="hamburger"
            onClick={openDrawer}
          />
        )}
      </NavWrapper>
    </>
  );
};

export default Navbar;
