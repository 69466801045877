import { Typography } from "@mui/material";
import { Rate } from "antd";
import React from "react";
import { ImageList } from "../../../../config/ImageList";
import useViewport from "../../../../viewport/useViewport";
import { LocationWrapper } from "./Style";

const GoogleLocation = () => {
  const { isMobile, isTablet } = useViewport();

  return (
    <LocationWrapper isMobile={isMobile} isTablet={isTablet}>
      {/* <img
        src={ImageList.Location_Image}
        alt="location"
        className="location_image"
      /> */}

      <iframe
        src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d29840.856853315505!2d72.83574240042796!3d19.147446073977218!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7b6fa84bae6e9%3A0xb06318811ede6629!2sYellow%20Box%20Casting!5e0!3m2!1sen!2sin!4v1677586423799!5m2!1sen!2sin"
        className="location_image"
        style={{ border: 0 }}
        allowfullscreen={true}
        loading="lazy"
        title="Map"
        referrerpolicy="no-referrer-when-downgrade"
      ></iframe>

      <div
        style={{ widt: "100%", height: "fit-content" }}
        className="flexCenter"
      >
        <a
          href="https://goo.gl/maps/pjWGPEyGZfpuCwKY9"
          target="_blank"
          rel="noopener noreferrer"
          className="action_info_container"
        >
          {/* company name in english and hindi */}
          <div style={{ padding: "1rem 1rem 0 1rem" }}>
            <Typography className="fontOpen company_name">
              Yellow Box Casting
            </Typography>
            <Typography className="fontOpen hindi">
              येलो बॉक्स कास्टिंग
            </Typography>
          </div>
          {/* rating */}
          <div className="rating_container mtp4">
            <Typography className="fontOpen rating_number">4.5</Typography>
            <Rate
              allowHalf
              disabled
              defaultValue={4.5}
              className="rating_star"
            />
            <Typography className="fontOpen rating_text">8 reviews</Typography>
          </div>
          {/* divider */}
          <div className="divider" />
          {/* action image */}

          <img
            src={ImageList.GMap_Options_Image}
            alt="location"
            className="gMap_option_image"
          />
        </a>
      </div>
    </LocationWrapper>
  );
};

export default GoogleLocation;
