import { Result, Button } from "antd";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { getYoutubeAccessTokenAPI } from "../../functions/instagram";
import { setYouTubeAccessToken } from "../../redux/youtubeSlice";
// A custom hook that builds on useLocation to parse
// the query string for you.
function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}
const YoutubeThank = () => {
  const query = useQuery();

  const user = useSelector((state) => state.user);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  useEffect(() => {
    const myUserId =
      localStorage.getItem("USER_ID_YOU_TUBE") !== ""
        ? JSON.parse(localStorage.getItem("USER_ID_YOU_TUBE"))
        : "";

    getYoutubeAccessTokenAPI(myUserId, query.get("code"))
      .then((res) => {
        dispatch(setYouTubeAccessToken({ data: res.data.youtubeData }));
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <div
      className="flexCenter"
      style={{
        height: "100vh",
      }}
    >
      <Result
        status="success"
        title="Successfully linked the Youtube profile!"
        subTitle="You can move back to the app and enjoy"
        extra={[
          <Button type="primary" key="console" disabled>
            Go Back To App
          </Button>,
        ]}
      />
    </div>
  );
};

export default YoutubeThank;
