export const TermsData = [
  {
    heading: "Terms and Conditions",
    smallHead:
      "Terms and Conditions of Ingenkart - Influencer Marketing Platform",
    textData: `Welcome to Ingenkart! These Terms and Conditions govern your access to and use of the Ingenkart website and services. By using Ingenkart, you agree to comply with these terms. Please read them carefully.`,
    textData2: `Last Update: 13. March 2023`,
  },
  {
    heading: "Table of Contents",
    dataArr: [
      "Account Registration",
      "User Obligations",
      "Influencer Campaigns",
      "Payments",
      "Intellectual Property",
      "Confidentiality",
      "Limitation of Liability",
      "Termination",
      "Modifications",
      "Governing Law",
    ],
  },
  {
    heading: "1. Account Registration:",
    textData1: "1.1. You must be at least 13 years old to use Ingenkart.",
    textData2:
      "1.2. You are responsible for maintaining the confidentiality of your account credentials and are liable for any activities conducted through your account.",
  },
  {
    heading: "2. User Obligations:",
    textData1:
      "2.1. You agree to use Ingenkart for lawful purposes and in compliance with all applicable laws and regulations.",
    textData2:
      "2.2. You are responsible for the content you share, including ensuring it does not infringe on any third-party rights or violate any laws.",
    textData3:
      "2.3. You agree not to engage in any fraudulent, misleading, or deceptive practices on the platform.",
  },
  {
    heading: "3. Influencer Campaigns:",
    textData1:
      "3.1. Ingenkart connects influencers and brands for influencer marketing campaigns.",
    textData2:
      "3.2. Influencers are responsible for the accuracy and authenticity of their profile information and content.",
    textData3:
      "3.3. Brands are responsible for providing clear instructions and requirements for influencer campaigns.",
  },
  {
    heading: "4. Payments:",
    textData1:
      "4.1. Ingenkart facilitates payments between brands and influencers.",
    textData2:
      "4.2. Brands agree to pay the agreed-upon fees to influencers for their services.",
    textData3:
      "4.3. Ingenkart retains a platform fee for the services provided.",
    textData4:
      "4.4. Payments are processed securely through the platform's designated payment gateway.",
  },
  {
    heading: "5. Intellectual Property:",
    textData1:
      "5.1. Ingenkart respects intellectual property rights. Users must not infringe on any third-party copyrights, trademarks, or other proprietary rights.",
    textData2:
      "5.2. Users retain ownership of their content but grant Ingenkart a non-exclusive, royalty-free licence to use, display, and promote their content on the platform.",
  },
  {
    heading: "6. Confidentiality:",
    textData1:
      "6.1. Users may have access to confidential information shared on the platform. Users agree to keep such information confidential and not disclose it to third parties.",
  },
  {
    heading: "7. Limitation of Liability:",
    textData1:
      "7.1. Ingenkart is not liable for any damages, losses, or liabilities arising from the use of the platform.",
    textData2: "7.2. Users use the platform at their own risk.",
    textData3:
      "7.3. Ingenkart  platform may contain links to third-party websites that are not owned or controlled by Kofluence. Ingenkart  has no control over, and assumes no responsibility for, the content, privacy policies, or practices of any third-party websites.",
  },
  {
    heading: "8. Termination:",
    textData1:
      "8.1. Ingenkart reserves the right to suspend or terminate user accounts for any violation of these terms or any other reason deemed necessary.",
  },
  {
    heading: "9. Modifications:",
    textData1:
      "9.1. Ingenkart may modify these terms and conditions at any time. Users will be notified of any changes, and continued use of the platform constitutes acceptance of the modified terms.",
  },
  {
    heading: "10. Governing Law:",
    textData1:
      "10.1. These terms and conditions shall be governed by and construed in accordance with the governing  laws of India.",
  },
];
