import React, { useState } from "react";
import { useFormik } from "formik";
import axios from "axios";
import useViewport from "../../viewport/useViewport";
import { ContactUsSchema } from "../../config/Schema";
import { Col, Input, message, Row, Spin } from "antd";
import { ContactFormWrapper } from "./Style";
import { Button, Typography } from "@mui/material";
import NormalLabel from "../inputLabel/NormalLabel";
import { ImageList } from "../../config/ImageList";

const ContactUsForm = () => {
  const { isTablet, isMobile } = useViewport();
  const [loading, setLoading] = useState(false);
  const initialValues = {
    email: "",
    message: "",
    name: "",
    phoneNumber: "",
    companyName: "",
  };
  // ===========================************==================================
  // ===========================useFormik=====================================
  // ===========================*************=================================
  const { handleSubmit, handleChange, handleBlur, values, errors, touched } =
    useFormik({
      enableReinitialize: true,
      initialValues: initialValues,
      validationSchema: ContactUsSchema,
      validateOnBlur: false,
      validateOnChange: true,

      onSubmit: (values, { setSubmitting, resetForm }) => {
        setLoading(true);
        console.log("Submitting...");
        axios.defaults.headers.post["Content-Type"] = "application/json";
        axios
          .post("https://formsubmit.co/ajax/theyellowboxdev@gmail.com", {
            Email: values?.email,
            Message: values?.message,
            Name: values?.name,
            "Phone Number": values?.phoneNumber,
            "Company Name": values?.companyName,
          })
          .then((response) => {
            message.success("We will connect with you soon!");
            setLoading(false);
            console.log("Submitted");
            resetForm();
          })
          .catch((error) => {
            message.error(
              "Error occured while connecting. Kindly reload the page and send again."
            );
            console.log(error);
            console.log("Error");
            setLoading(false);
            resetForm();
          });
      },
    });

  return (
    <ContactFormWrapper isMobile={isMobile} isTablet={isTablet}>
      <img
        src={ImageList.Message_Curve_Arrow}
        alt="message"
        className="message_curve_image"
      />
      <Typography className="fontLato heading">Reach us.</Typography>
      <Typography className="fontLato sub_heading mtp2">
        Let's Connect and Elevate Your Brand Together!
      </Typography>
      <Row gutter={[16, 24]} className="mt3">
        <Col xs={24} md={12} lg={12}>
          <NormalLabel identifier="name" title="Name*" />
          <Input
            type="text"
            name="name"
            id="name"
            value={values?.name}
            onChange={handleChange}
            className="input_container"
            onBlur={handleBlur}
            status={touched.name && errors.name ? "error" : null}
          />
        </Col>
        <Col xs={24} md={12} lg={12}>
          <NormalLabel identifier="companyName" title="Company Name" />
          <Input
            type="text"
            name="companyName"
            id="companyName"
            value={values?.companyName}
            onChange={handleChange}
            className="input_container"
          />
        </Col>
      </Row>
      {/* 2nd row for Email and Phone Number */}
      <Row gutter={[16, 24]} className="mt2">
        <Col xs={24} md={12} lg={12}>
          <NormalLabel identifier="email" title="Email*" />
          <Input
            type="email"
            name="email"
            id="email"
            value={values?.email}
            onChange={handleChange}
            className="input_container"
            onBlur={handleBlur}
            status={touched.email && errors.email ? "error" : null}
          />
        </Col>
        <Col xs={24} md={12} lg={12}>
          <NormalLabel identifier="phoneNumber" title="Phone Number*" />
          <Input
            type="tel"
            name="phoneNumber"
            id="phoneNumber"
            value={values?.phoneNumber}
            onChange={handleChange}
            className="input_container"
            onBlur={handleBlur}
            status={touched.phoneNumber && errors.phoneNumber ? "error" : null}
          />
        </Col>
      </Row>
      <Row gutter={[16, 24]} className="mt2">
        <Col xs={24} md={24} lg={24}>
          <NormalLabel identifier="message" title="Message/Notes.*" />
          <Input.TextArea
            autoSize={{
              minRows: 6,
              maxRows: 6,
            }}
            type="text"
            name="message"
            id="message"
            value={values?.message}
            onChange={handleChange}
            className="input_container"
            onBlur={handleBlur}
            status={touched.message && errors.message ? "error" : null}
          />
        </Col>
      </Row>
      <Button
        className="submit_button fontLato mt2"
        variant="contained"
        fullWidth
        disabled={loading}
        onClick={() => handleSubmit()}
      >
        {loading ? (
          <span>
            {" "}
            Connecting... <Spin />{" "}
          </span>
        ) : (
          "Submit"
        )}
      </Button>
    </ContactFormWrapper>
  );
};

export default ContactUsForm;
